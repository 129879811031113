<template>
  <div class="product-lists flex-col ali-center">
    <div class="topProductList flex-row jus-between" @click="toDetail">
      <div class="left flex-col jus-between">
        <div class="outer3 flex-col">
          <div class="layer1 flex-row">
            <div class="section1 flex-col">
              <div class="block1 flex-col"></div>
            </div>
            <div class="section2 flex-col">
              <span class="word4">产品一产品一</span>
              <div class="group3 flex-col"></div>
              <span class="infoBox1">
              
                <br />
                一产品一一产品一一产品一…
              </span>
              <div class="group4 flex-col">
                <div class="mod2 flex-row">
                  <span class="info2">MORE</span>
                  <img
                    class="label1"
                    referrerpolicy="no-referrer"
                    src="@/assets/product-display/icon1.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="outer4 flex-col">
          <div class="block2 flex-row">
            <div class="bd2 flex-col"><div class="mod3 flex-col"></div></div>
            <div class="bd3 flex-col">
              <span class="word5">产品一产品一</span>
              <div class="mod4 flex-col"></div>
              <span class="paragraph1">
                一产品一一产品一一产品一一产品一一产品一
                <br />
                一产品一一产品一一产品一…
              </span>
              <div class="mod5 flex-col">
                <div class="main2 flex-row">
                  <span class="info3">MORE</span>
                  <img
                    class="icon1"
                    referrerpolicy="no-referrer"
                    src="@/assets/product-display/icon1.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="mod6 flex-col">
          <div class="mod7 flex-col">
            <span class="info4">产品一产品一产品一</span>
            <div class="layer2 flex-col"></div>
            <span class="infoBox2">
              一产品一产品一产品一产品一产品一产品一产品一产品一产品一产品
              <br />
              一产品一产品一产品一产品一产品一产品一产品一产品一产品一…
            </span>
            <div class="layer3 flex-col">
              <div class="mod8 flex-row">
                <span class="word6">MORE</span>
                <img
                  class="icon2"
                  referrerpolicy="no-referrer"
                  src="@/assets/product-display/icon1.png"
                />
              </div>
            </div>
            <div class="layer4 flex-col">
              <div class="section3 flex-col"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottomProducts flex-row jus-between">
      <div
        class="layer8 flex-col"
        v-for="(item, index) in loopData0"
        :key="index"
      >
        <div class="title">软件开发</div>
        <div class="main3 flex-col">
          <div class="bd4 flex-col">
            <img
              class="img2"
              referrerpolicy="no-referrer"
              :src="item.lanhuimage0"
            />
          </div>
          <span class="word10" v-html="item.lanhutext0"></span>
          <div class="bd5 flex-col"></div>
          <span class="word11" v-html="item.lanhutext1"></span>
          <div class="bd6 flex-col">
            <div class="outer7 flex-row">
              <span class="word12">MORE</span>
              <img
                class="icon3"
                referrerpolicy="no-referrer"
                src="@/assets/product-display/icon1.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductLists",
  data() {
    return {
      loopData0: [
        {
          lanhuimage0: require('@/assets/project-case/caseImg.png'),
          lanhutext0: "智慧健康综合应用系统",
          lanhutext1: "智慧健康综合应用系统智慧健康综合应用系统智慧<br/>健康综合应用系统智慧健康综合应用系统智慧…",
        },
        {
          lanhuimage0: require('@/assets/project-case/caseImg.png'),
          lanhutext0: "终端设备体验系统",
          lanhutext1: "智慧健康综合应用系统智慧健康综合应用系统智慧<br/>健康综合应用系统智慧健康综合应用系统智慧…",
        },
        {
          lanhuimage0: require('@/assets/project-case/caseImg.png'),
          lanhutext0: "线上健康小程序",
          lanhutext1: "智慧健康综合应用系统智慧健康综合应用系统智慧<br/>健康综合应用系统智慧健康综合应用系统智慧…",
        },
      ],
      constants: {},
    };
  },
  methods: {
    toDetail(){
      this.$router.push({name:'product-detail'})
    }
  },
};
</script>

<style lang="stylus" scoped>
.product-lists {
  padding-top: 85px;
  padding-bottom: 400px;

  > div {
    width: 1200px;
  }

  .topProductList {
    height: 675px;
    margin-bottom: 60px;
  }
}

.title {
  background: #fff;
  height: 57px;
  text-align: left;
  position: relative;
  font-size: 26px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #019FD8;
  line-height: 37px;
  padding-left: 15px;

  &:before {
    content: '';
    display: block;
    width: 4px;
    height: 25px;
    background: #019FD8;
    position: absolute;
    left: 0;
    top: 6px;
  }
}

.layer8 {
  z-index: 93;
  height: 557px;
  background-color: rgba(242, 249, 255, 1);
  margin-right: 15px;
  width: 390px;
  justify-content: flex-start;

  .main3 {
    z-index: auto;
    width: 390px;
    height: 476px;

    .bd4 {
      z-index: 95;
      height: 240px;
      background-color: rgba(194, 213, 230, 1);
      width: 390px;

      .img2 {
        z-index: 96;
        width: 390px;
        height: 240px;
      }
    }

    .word10 {
      z-index: 97;
      width: 240px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.85);
      font-size: 24px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 33px;
      text-align: left;
      align-self: center;
      margin-top: 31px;
    }

    .bd5 {
      z-index: 106;
      width: 350px;
      height: 1px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17c6c76f35affe0fd9510278fd5afd4eb6fb49eea0f4075f22413010a370fc7a'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17c6c76f35affe0fd9510278fd5afd4eb6fb49eea0f4075f22413010a370fc7a)
      0px -1px no-repeat;
      align-self: center;
      margin-top: 15px;
    }

    .word11 {
      z-index: 105;
      width: 308px;
      height: 60px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(109, 114, 120, 1);
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      align-self: center;
      margin-top: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .bd6 {
      z-index: 99;
      height: 37px;
      border-radius: 2px;
      border-width: 1px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background-color: rgba(242, 249, 255, 1);
      align-self: center;
      margin-top: 49px;
      width: 140px;
      justify-content: center;
      align-items: center;

      .outer7 {
        z-index: auto;
        width: 52px;
        height: 17px;
        justify-content: space-between;

        .word12 {
          z-index: 101;
          width: 36px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          white-space: nowrap;
          line-height: 17px;
          text-align: left;
        }

        .icon3 {
          z-index: 102;
          width: 13px;
          height: 13px;
          background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
          100% no-repeat;
          margin-top: 2px;
        }
      }
    }
  }
}

.main3 {
  z-index: auto;
  width: 390px;
  height: 476px;

  .bd4 {
    z-index: 95;
    height: 240px;
    background-color: rgba(194, 213, 230, 1);
    width: 390px;

    .img2 {
      z-index: 96;
      width: 390px;
      height: 240px;
    }
  }

  .word10 {
    z-index: 97;
    width: 240px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 33px;
    text-align: left;
    align-self: center;
    margin-top: 31px;
  }

  .bd5 {
    z-index: 106;
    width: 350px;
    height: 1px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17c6c76f35affe0fd9510278fd5afd4eb6fb49eea0f4075f22413010a370fc7a'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17c6c76f35affe0fd9510278fd5afd4eb6fb49eea0f4075f22413010a370fc7a)
    0px -1px no-repeat;
    align-self: center;
    margin-top: 15px;
  }

  .word11 {
    z-index: 105;
    width: 308px;
    height: 60px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(109, 114, 120, 1);
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    align-self: center;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bd6 {
    z-index: 99;
    height: 37px;
    border-radius: 2px;
    border-width: 1px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(242, 249, 255, 1);
    align-self: center;
    margin-top: 49px;
    width: 140px;
    justify-content: center;
    align-items: center;

    .outer7 {
      z-index: auto;
      width: 52px;
      height: 17px;
      justify-content: space-between;

      .word12 {
        z-index: 101;
        width: 36px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
      }

      .icon3 {
        z-index: 102;
        width: 13px;
        height: 13px;
        background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
        100% no-repeat;
        margin-top: 2px;
      }
    }
  }
}

.bd4 {
  z-index: 95;
  height: 240px;
  background-color: rgba(194, 213, 230, 1);
  width: 390px;

  .img2 {
    z-index: 96;
    width: 390px;
    height: 240px;
  }
}

.img2 {
  z-index: 96;
  width: 390px;
  height: 240px;
}

.word10 {
  z-index: 97;
  width: 240px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: PingFangSC-Semibold;
  white-space: nowrap;
  line-height: 33px;
  text-align: left;
  align-self: center;
  margin-top: 31px;
}

.bd5 {
  z-index: 106;
  width: 350px;
  height: 1px;
  background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17c6c76f35affe0fd9510278fd5afd4eb6fb49eea0f4075f22413010a370fc7a'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng17c6c76f35affe0fd9510278fd5afd4eb6fb49eea0f4075f22413010a370fc7a)
  0px -1px no-repeat;
  align-self: center;
  margin-top: 15px;
}

.word11 {
  z-index: 105;
  width: 308px;
  height: 60px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(109, 114, 120, 1);
  font-size: 14px;
  line-height: 30px;
  text-align: left;
  align-self: center;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bd6 {
  z-index: 99;
  height: 37px;
  border-radius: 2px;
  border-width: 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(242, 249, 255, 1);
  align-self: center;
  margin-top: 49px;
  width: 140px;
  justify-content: center;
  align-items: center;

  .outer7 {
    z-index: auto;
    width: 52px;
    height: 17px;
    justify-content: space-between;

    .word12 {
      z-index: 101;
      width: 36px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
    }

    .icon3 {
      z-index: 102;
      width: 13px;
      height: 13px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
      100% no-repeat;
      margin-top: 2px;
    }
  }
}

.outer7 {
  z-index: auto;
  width: 52px;
  height: 17px;
  justify-content: space-between;

  .word12 {
    z-index: 101;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
  }

  .icon3 {
    z-index: 102;
    width: 13px;
    height: 13px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
    100% no-repeat;
    margin-top: 2px;
  }
}

.word12 {
  z-index: 101;
  width: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  white-space: nowrap;
  line-height: 17px;
  text-align: left;
}

.icon3 {
  z-index: 102;
  width: 13px;
  height: 13px;
  background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
  100% no-repeat;
  margin-top: 2px;
}

.outer3 {
  z-index: 61;
  height: 330px;
  background-color: rgba(242, 249, 255, 1);
  width: 660px;
  justify-content: center;
  align-items: flex-start;
  padding-left: 18px;

  .layer1 {
    z-index: auto;
    width: 610px;
    height: 229px;
    justify-content: space-between;

    .section1 {
      z-index: 74;
      position: relative;
      width: 300px;
      height: 198px;
      overflow: hidden;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga60db17b57ddb7f9611b522568466880a561f6b2d497a3310e939aa4e484af48'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga60db17b57ddb7f9611b522568466880a561f6b2d497a3310e939aa4e484af48)
      0px 0px no-repeat;
      margin-top: 16px;

      .block1 {
        z-index: 75;
        position: absolute;
        left: -8px;
        top: 0;
        width: 317px;
        height: 198px;
        background: url('../assets/product-display/product1.png') 8px 0px no-repeat;
      }
    }

    .section2 {
      z-index: auto;
      width: 280px;
      height: 229px;

      .word4 {
        z-index: 70;
        width: 132px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.85);
        font-size: 22px;
        font-family: PingFangSC-Semibold;
        white-space: nowrap;
        line-height: 30px;
        text-align: left;
        align-self: flex-start;
      }

      .group3 {
        z-index: 72;
        width: 280px;
        height: 1px;
        background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91c15e6a73662fd14cd1f95a7f994e5ba4051f340caf574b9b358f84f667e281'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91c15e6a73662fd14cd1f95a7f994e5ba4051f340caf574b9b358f84f667e281)
        0px -1px no-repeat;
        margin-top: 25px;
      }

      .infoBox1 {
        z-index: 71;
        width: 280px;
        height: 60px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(109, 114, 120, 1);
        font-size: 14px;
        line-height: 30px;
        text-align: left;
        margin-top: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .group4 {
        z-index: 64;
        height: 37px;
        border-radius: 2px;
        border-width: 1px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: rgba(242, 249, 255, 1);
        align-self: flex-start;
        margin-top: 52px;
        width: 140px;
        justify-content: center;
        align-items: center;

        .mod2 {
          z-index: auto;
          width: 52px;
          height: 17px;
          justify-content: space-between;

          .info2 {
            z-index: 66;
            width: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
            white-space: nowrap;
            line-height: 17px;
            text-align: left;
          }

          .label1 {
            z-index: 67;
            width: 13px;
            height: 13px;
            background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
            100% no-repeat;
            margin-top: 2px;
          }
        }
      }
    }
  }
}

.layer1 {
  z-index: auto;
  width: 610px;
  height: 229px;
  justify-content: space-between;

  .section1 {
    z-index: 74;
    position: relative;
    width: 300px;
    height: 198px;
    overflow: hidden;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga60db17b57ddb7f9611b522568466880a561f6b2d497a3310e939aa4e484af48'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga60db17b57ddb7f9611b522568466880a561f6b2d497a3310e939aa4e484af48)
    0px 0px no-repeat;
    margin-top: 16px;

  }

  .section2 {
    z-index: auto;
    width: 280px;
    height: 229px;

    .word4 {
      z-index: 70;
      width: 132px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.85);
      font-size: 22px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 30px;
      text-align: left;
      align-self: flex-start;
    }

    .group3 {
      z-index: 72;
      width: 280px;
      height: 1px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91c15e6a73662fd14cd1f95a7f994e5ba4051f340caf574b9b358f84f667e281'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91c15e6a73662fd14cd1f95a7f994e5ba4051f340caf574b9b358f84f667e281)
      0px -1px no-repeat;
      margin-top: 25px;
    }

    .infoBox1 {
      z-index: 71;
      width: 280px;
      height: 60px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(109, 114, 120, 1);
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      margin-top: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .group4 {
      z-index: 64;
      height: 37px;
      border-radius: 2px;
      border-width: 1px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background-color: rgba(242, 249, 255, 1);
      align-self: flex-start;
      margin-top: 52px;
      width: 140px;
      justify-content: center;
      align-items: center;

      .mod2 {
        z-index: auto;
        width: 52px;
        height: 17px;
        justify-content: space-between;

        .info2 {
          z-index: 66;
          width: 36px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          white-space: nowrap;
          line-height: 17px;
          text-align: left;
        }

        .label1 {
          z-index: 67;
          width: 13px;
          height: 13px;
          background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
          100% no-repeat;
          margin-top: 2px;
        }
      }
    }
  }
}

.section2 {
  z-index: auto;
  width: 280px;
  height: 229px;

  .word4 {
    z-index: 70;
    width: 132px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.85);
    font-size: 22px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    align-self: flex-start;
  }

  .group3 {
    z-index: 72;
    width: 280px;
    height: 1px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91c15e6a73662fd14cd1f95a7f994e5ba4051f340caf574b9b358f84f667e281'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91c15e6a73662fd14cd1f95a7f994e5ba4051f340caf574b9b358f84f667e281)
    0px -1px no-repeat;
    margin-top: 25px;
  }

  .infoBox1 {
    z-index: 71;
    width: 280px;
    height: 60px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(109, 114, 120, 1);
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    margin-top: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .group4 {
    z-index: 64;
    height: 37px;
    border-radius: 2px;
    border-width: 1px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(242, 249, 255, 1);
    align-self: flex-start;
    margin-top: 52px;
    width: 140px;
    justify-content: center;
    align-items: center;

    .mod2 {
      z-index: auto;
      width: 52px;
      height: 17px;
      justify-content: space-between;

      .info2 {
        z-index: 66;
        width: 36px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
      }

      .label1 {
        z-index: 67;
        width: 13px;
        height: 13px;
        background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
        100% no-repeat;
        margin-top: 2px;
      }
    }
  }
}

.word4 {
  z-index: 70;
  width: 132px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.85);
  font-size: 22px;
  font-family: PingFangSC-Semibold;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  align-self: flex-start;
}

.group3 {
  z-index: 72;
  width: 280px;
  height: 1px;
  background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91c15e6a73662fd14cd1f95a7f994e5ba4051f340caf574b9b358f84f667e281'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91c15e6a73662fd14cd1f95a7f994e5ba4051f340caf574b9b358f84f667e281)
  0px -1px no-repeat;
  margin-top: 25px;
}

.infoBox1 {
  z-index: 71;
  width: 280px;
  height: 60px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(109, 114, 120, 1);
  font-size: 14px;
  line-height: 30px;
  text-align: left;
  margin-top: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.group4 {
  z-index: 64;
  height: 37px;
  border-radius: 2px;
  border-width: 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(242, 249, 255, 1);
  align-self: flex-start;
  margin-top: 52px;
  width: 140px;
  justify-content: center;
  align-items: center;

  .mod2 {
    z-index: auto;
    width: 52px;
    height: 17px;
    justify-content: space-between;

    .info2 {
      z-index: 66;
      width: 36px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
    }

    .label1 {
      z-index: 67;
      width: 13px;
      height: 13px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
      100% no-repeat;
      margin-top: 2px;
    }
  }
}

.mod2 {
  z-index: auto;
  width: 52px;
  height: 17px;
  justify-content: space-between;

  .info2 {
    z-index: 66;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
  }

  .label1 {
    z-index: 67;
    width: 13px;
    height: 13px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
    100% no-repeat;
    margin-top: 2px;
  }
}

.info2 {
  z-index: 66;
  width: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  white-space: nowrap;
  line-height: 17px;
  text-align: left;
}

.label1 {
  z-index: 67;
  width: 13px;
  height: 13px;
  background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
  100% no-repeat;
  margin-top: 2px;
}

.outer4 {
  z-index: 45;
  height: 330px;
  background-color: rgba(242, 249, 255, 1);
  margin-top: 15px;
  width: 660px;
  justify-content: center;
  align-items: flex-start;
  padding-left: 18px;

  .block2 {
    z-index: auto;
    width: 610px;
    height: 229px;
    justify-content: space-between;

    .bd2 {
      z-index: 58;
      position: relative;
      width: 300px;
      height: 198px;
      overflow: hidden;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga60db17b57ddb7f9611b522568466880a561f6b2d497a3310e939aa4e484af48'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga60db17b57ddb7f9611b522568466880a561f6b2d497a3310e939aa4e484af48)
      100% no-repeat;
      margin-top: 16px;

      .mod3 {
        z-index: 59;
        position: absolute;
        left: -8px;
        top: 0;
        width: 317px;
        height: 198px;
        background: url('../assets/product-display/product1.png') 8px 0px no-repeat;
      }
    }

    .bd3 {
      z-index: auto;
      width: 280px;
      height: 229px;

      .word5 {
        z-index: 54;
        width: 132px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.85);
        font-size: 22px;
        font-family: PingFangSC-Semibold;
        white-space: nowrap;
        line-height: 30px;
        text-align: left;
        align-self: flex-start;
      }

      .mod4 {
        z-index: 56;
        width: 280px;
        height: 1px;
        background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91c15e6a73662fd14cd1f95a7f994e5ba4051f340caf574b9b358f84f667e281'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91c15e6a73662fd14cd1f95a7f994e5ba4051f340caf574b9b358f84f667e281)
        0px -1px no-repeat;
        margin-top: 25px;
      }

      .paragraph1 {
        z-index: 55;
        width: 280px;
        height: 60px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(109, 114, 120, 1);
        font-size: 14px;
        line-height: 30px;
        text-align: left;
        margin-top: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .mod5 {
        z-index: 48;
        height: 37px;
        border-radius: 2px;
        border-width: 1px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: rgba(242, 249, 255, 1);
        align-self: flex-start;
        margin-top: 52px;
        width: 140px;
        justify-content: center;
        align-items: center;

        .main2 {
          z-index: auto;
          width: 52px;
          height: 17px;
          justify-content: space-between;

          .info3 {
            z-index: 50;
            width: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
            white-space: nowrap;
            line-height: 12px;
            text-align: left;
          }

          .icon1 {
            z-index: 51;
            width: 13px;
            height: 13px;
            background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
            100% no-repeat;
            margin-top: 2px;
          }
        }
      }
    }
  }
}

.block2 {
  z-index: auto;
  width: 610px;
  height: 229px;
  justify-content: space-between;

  .bd2 {
    z-index: 58;
    position: relative;
    width: 300px;
    height: 198px;
    overflow: hidden;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga60db17b57ddb7f9611b522568466880a561f6b2d497a3310e939aa4e484af48'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga60db17b57ddb7f9611b522568466880a561f6b2d497a3310e939aa4e484af48)
    100% no-repeat;
    margin-top: 16px;

  }

  .bd3 {
    z-index: auto;
    width: 280px;
    height: 229px;

    .word5 {
      z-index: 54;
      width: 132px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.85);
      font-size: 22px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 30px;
      text-align: left;
      align-self: flex-start;
    }

    .mod4 {
      z-index: 56;
      width: 280px;
      height: 1px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91c15e6a73662fd14cd1f95a7f994e5ba4051f340caf574b9b358f84f667e281'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91c15e6a73662fd14cd1f95a7f994e5ba4051f340caf574b9b358f84f667e281)
      0px -1px no-repeat;
      margin-top: 25px;
    }

    .paragraph1 {
      z-index: 55;
      width: 280px;
      height: 60px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(109, 114, 120, 1);
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      margin-top: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .mod5 {
      z-index: 48;
      height: 37px;
      border-radius: 2px;
      border-width: 1px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background-color: rgba(242, 249, 255, 1);
      align-self: flex-start;
      margin-top: 52px;
      width: 140px;
      justify-content: center;
      align-items: center;

      .main2 {
        z-index: auto;
        width: 52px;
        height: 17px;
        justify-content: space-between;

        .info3 {
          z-index: 50;
          width: 36px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          white-space: nowrap;
          line-height: 12px;
          text-align: left;
        }

        .icon1 {
          z-index: 51;
          width: 13px;
          height: 13px;
          background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
          100% no-repeat;
          margin-top: 2px;
        }
      }
    }
  }
}

.bd2 {
  z-index: 58;
  position: relative;
  width: 300px;
  height: 198px;
  overflow: hidden;
  background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga60db17b57ddb7f9611b522568466880a561f6b2d497a3310e939aa4e484af48'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga60db17b57ddb7f9611b522568466880a561f6b2d497a3310e939aa4e484af48)
  100% no-repeat;
  margin-top: 16px;
}

.bd3 {
  z-index: auto;
  width: 280px;
  height: 229px;

  .word5 {
    z-index: 54;
    width: 132px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.85);
    font-size: 22px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    align-self: flex-start;
  }

  .mod4 {
    z-index: 56;
    width: 280px;
    height: 1px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91c15e6a73662fd14cd1f95a7f994e5ba4051f340caf574b9b358f84f667e281'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91c15e6a73662fd14cd1f95a7f994e5ba4051f340caf574b9b358f84f667e281)
    0px -1px no-repeat;
    margin-top: 25px;
  }

  .paragraph1 {
    z-index: 55;
    width: 280px;
    height: 60px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(109, 114, 120, 1);
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    margin-top: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mod5 {
    z-index: 48;
    height: 37px;
    border-radius: 2px;
    border-width: 1px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(242, 249, 255, 1);
    align-self: flex-start;
    margin-top: 52px;
    width: 140px;
    justify-content: center;
    align-items: center;

    .main2 {
      z-index: auto;
      width: 52px;
      height: 17px;
      justify-content: space-between;

      .info3 {
        z-index: 50;
        width: 36px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 12px;
        text-align: left;
      }

      .icon1 {
        z-index: 51;
        width: 13px;
        height: 13px;
        background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
        100% no-repeat;
        margin-top: 2px;
      }
    }
  }
}

.word5 {
  z-index: 54;
  width: 132px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.85);
  font-size: 22px;
  font-family: PingFangSC-Semibold;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  align-self: flex-start;
}

.mod4 {
  z-index: 56;
  width: 280px;
  height: 1px;
  background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91c15e6a73662fd14cd1f95a7f994e5ba4051f340caf574b9b358f84f667e281'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91c15e6a73662fd14cd1f95a7f994e5ba4051f340caf574b9b358f84f667e281)
  0px -1px no-repeat;
  margin-top: 25px;
}

.paragraph1 {
  z-index: 55;
  width: 280px;
  height: 60px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(109, 114, 120, 1);
  font-size: 14px;
  line-height: 30px;
  text-align: left;
  margin-top: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mod5 {
  z-index: 48;
  height: 37px;
  border-radius: 2px;
  border-width: 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(242, 249, 255, 1);
  align-self: flex-start;
  margin-top: 52px;
  width: 140px;
  justify-content: center;
  align-items: center;

  .main2 {
    z-index: auto;
    width: 52px;
    height: 17px;
    justify-content: space-between;

    .info3 {
      z-index: 50;
      width: 36px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 12px;
      text-align: left;
    }

    .icon1 {
      z-index: 51;
      width: 13px;
      height: 13px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
      100% no-repeat;
      margin-top: 2px;
    }
  }
}

.main2 {
  z-index: auto;
  width: 52px;
  height: 17px;
  justify-content: space-between;

  .info3 {
    z-index: 50;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 12px;
    text-align: left;
  }

  .icon1 {
    z-index: 51;
    width: 13px;
    height: 13px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
    100% no-repeat;
    margin-top: 2px;
  }
}

.info3 {
  z-index: 50;
  width: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  white-space: nowrap;
  line-height: 12px;
  text-align: left;
}

.icon1 {
  z-index: 51;
  width: 13px;
  height: 13px;
  background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
  100% no-repeat;
  margin-top: 2px;
}

.mod6 {
  z-index: 77;
  height: 675px;
  background-color: rgba(242, 249, 255, 1);
  width: 500px;
  justify-content: center;
  align-items: flex-end;
  padding-right: 20px;

  .mod7 {
    z-index: auto;
    width: 455px;
    height: 582px;

    .info4 {
      z-index: 88;
      width: 198px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.85);
      font-size: 22px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 30px;
      text-align: left;
      align-self: flex-start;
    }

    .layer2 {
      z-index: 90;
      width: 450px;
      height: 1px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4b9cef44ce76543803690d4776416b5eff74101bce1edcfa7b644e858afd04e7'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4b9cef44ce76543803690d4776416b5eff74101bce1edcfa7b644e858afd04e7)
      0px -1px no-repeat;
      align-self: flex-start;
      margin-top: 25px;
    }

    .infoBox2 {
      z-index: 89;
      width: 420px;
      height: 60px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(109, 114, 120, 1);
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      align-self: flex-start;
      margin-top: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .layer3 {
      z-index: 82;
      height: 37px;
      border-radius: 2px;
      border-width: 1px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background-color: rgba(242, 249, 255, 1);
      align-self: flex-start;
      width: 140px;
      justify-content: center;
      align-items: center;
      margin: 52px 0 0 19px;

      .mod8 {
        z-index: auto;
        width: 52px;
        height: 17px;
        justify-content: space-between;

        .word6 {
          z-index: 84;
          width: 36px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          white-space: nowrap;
          line-height: 17px;
          text-align: left;
        }

        .icon2 {
          z-index: 85;
          width: 13px;
          height: 13px;
          background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
          100% no-repeat;
          margin-top: 2px;
        }
      }
    }

    .layer4 {
      z-index: 79;
      position: relative;
      width: 450px;
      height: 290px;
      overflow: hidden;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2603ceb5bc885f0d417312f55eda52aa74a16d8ce75a692e0bce182042a8a3ed'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2603ceb5bc885f0d417312f55eda52aa74a16d8ce75a692e0bce182042a8a3ed)
      0px 0px no-repeat;
      align-self: flex-end;
      margin-top: 63px;

      .section3 {
        z-index: 80;
        position: absolute;
        left: -6px;
        top: 0;
        width: 464px;
        height: 290px;
        background: url('../assets/product-display/product2.png') 7px 0px no-repeat;
      }
    }
  }
}

.mod7 {
  z-index: auto;
  width: 455px;
  height: 582px;

  .info4 {
    z-index: 88;
    width: 198px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.85);
    font-size: 22px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    align-self: flex-start;
  }

  .layer2 {
    z-index: 90;
    width: 450px;
    height: 1px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4b9cef44ce76543803690d4776416b5eff74101bce1edcfa7b644e858afd04e7'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4b9cef44ce76543803690d4776416b5eff74101bce1edcfa7b644e858afd04e7)
    0px -1px no-repeat;
    align-self: flex-start;
    margin-top: 25px;
  }

  .infoBox2 {
    z-index: 89;
    width: 420px;
    height: 60px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(109, 114, 120, 1);
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    align-self: flex-start;
    margin-top: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .layer3 {
    z-index: 82;
    height: 37px;
    border-radius: 2px;
    border-width: 1px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(242, 249, 255, 1);
    align-self: flex-start;
    width: 140px;
    justify-content: center;
    align-items: center;
    margin: 52px 0 0 19px;

    .mod8 {
      z-index: auto;
      width: 52px;
      height: 17px;
      justify-content: space-between;

      .word6 {
        z-index: 84;
        width: 36px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
      }

      .icon2 {
        z-index: 85;
        width: 13px;
        height: 13px;
        background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
        100% no-repeat;
        margin-top: 2px;
      }
    }
  }

}

.info4 {
  z-index: 88;
  width: 198px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.85);
  font-size: 22px;
  font-family: PingFangSC-Semibold;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  align-self: flex-start;
}

.layer2 {
  z-index: 90;
  width: 450px;
  height: 1px;
  background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4b9cef44ce76543803690d4776416b5eff74101bce1edcfa7b644e858afd04e7'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4b9cef44ce76543803690d4776416b5eff74101bce1edcfa7b644e858afd04e7)
  0px -1px no-repeat;
  align-self: flex-start;
  margin-top: 25px;
}

.infoBox2 {
  z-index: 89;
  width: 420px;
  height: 60px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(109, 114, 120, 1);
  font-size: 14px;
  line-height: 30px;
  text-align: left;
  align-self: flex-start;
  margin-top: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.layer3 {
  z-index: 82;
  height: 37px;
  border-radius: 2px;
  border-width: 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(242, 249, 255, 1);
  align-self: flex-start;
  width: 140px;
  justify-content: center;
  align-items: center;
  margin: 52px 0 0 19px;

  .mod8 {
    z-index: auto;
    width: 52px;
    height: 17px;
    justify-content: space-between;

    .word6 {
      z-index: 84;
      width: 36px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
    }

    .icon2 {
      z-index: 85;
      width: 13px;
      height: 13px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
      100% no-repeat;
      margin-top: 2px;
    }
  }
}

.mod8 {
  z-index: auto;
  width: 52px;
  height: 17px;
  justify-content: space-between;

  .word6 {
    z-index: 84;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
  }

  .icon2 {
    z-index: 85;
    width: 13px;
    height: 13px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
    100% no-repeat;
    margin-top: 2px;
  }
}

.word6 {
  z-index: 84;
  width: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  white-space: nowrap;
  line-height: 17px;
  text-align: left;
}

.icon2 {
  z-index: 85;
  width: 13px;
  height: 13px;
  background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
  100% no-repeat;
  margin-top: 2px;
}

</style>